<template>
  <!-- 轮播图 -->
  <div id="app">
    <el-container>
      <!-- 头部 -->
      <el-header>
        <el-menu class="el-menu-demo" mode="horizontal">
          <el-menu-item index="1" @click="shouye">首页</el-menu-item>
          <el-menu-item index="1" @click="router1">歌手</el-menu-item>
          <el-menu-item index="1" @click="myMv">Mv</el-menu-item>
          <el-menu-item index="1" @click="rank">排行榜</el-menu-item>
          <el-menu-item index="1" @click="mymusic">我的音乐</el-menu-item>
          <el-menu-item index="1">
            <!-- 搜索 -->
            <el-form ref="form" :model="form" label-width="0px">
              <el-form-item class="search">
                <el-input
                  v-model="form.content"
                  placeholder="歌手 歌单 歌曲"
                  size="medium"
                  suffix-icon="el-icon-search"
                ></el-input>
              </el-form-item>
            </el-form>
          </el-menu-item>
          <!-- 登录 -->
          <el-menu-item index="1">
            <el-breadcrumb separator="/">
              <el-breadcrumb-item
                :to="{ path: '/login' }"
                class="login"
                style="cursor: default; position: absolute; left: 350px"
                ><span style="cursor: default" id="judgelogin"
                  >登录</span
                ></el-breadcrumb-item
              >
            </el-breadcrumb>
          </el-menu-item>
          <!-- 头像 -->
          <el-menu-item>
            <div class="user">
              <el-avatar :src="url"></el-avatar>
            </div>
          </el-menu-item>
        </el-menu>
      </el-header>
      <!-- 中间部分 -->
      <div class="div" style="margin-top: 1px">
        <div class="div_1">
          <el-menu class="el-menu-demo" mode="horizontal">
            <el-menu-item index="1">歌曲</el-menu-item>
            <el-menu-item index="1">歌手</el-menu-item>
            <el-menu-item index="1">分类·歌单</el-menu-item>
            <el-menu-item index="1">MV</el-menu-item>
          </el-menu>
        </div>
      </div>
      <!-- 中间部分 -->
      <el-main>
        <div class="main_header">
          <div class="img" v-if="imgUrl">
            <el-image
              style="width: 224px; height: 224px"
              :src="imgurl"
            ></el-image>
          </div>
          <div class="content">
            <div class="c_content">
              <h1
                style="
                  height: 40px;
                  line-height: 0;
                  text-align: left;
                  margin-left: 20px;
                  font-size: 31px;
                "
              >
                {{ title }}
              </h1>
            </div>
            <div class="type">标签： 流行</div>
            <div class="play">播放量： {{ playCount }}</div>
            <div class="fav">收藏量： {{ favCount }}</div>
            <!-- 播放全部按钮 -->
            <div class="playall">
              <el-button type="primary" @click="handleEdit(0, tableSongData)"
                >播放全部</el-button
              >
            </div>
          </div>
        </div>
        <div class="main_c">
          <h2 style="text-align: left; line-height: 2; margin-bottom: 1px">
            热门歌曲
          </h2>
        </div>
        <el-table
          stripe
          ref="singleTable"
          :data="tableSongData"
          style="
            width: 80%;
            float: left;
            margin-right: 20px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          "
        >
          <el-table-column type="index" width="50"> </el-table-column>
          <el-table-column property="songName" label="歌曲" width="0">
          </el-table-column>
          <el-table-column
            property="singerName"
            label="歌手"
            width="0"
            align="center"
          >
          </el-table-column>
          <el-table-column
            property="albumTitle"
            label="专辑"
            width="0"
            align="center"
          >
          </el-table-column>
          <el-table-column property="timeInterval" label="时长" align="center">
          </el-table-column>
          <el-table-column label="">
            <template slot-scope="scope">
              <el-button
                style="border: none"
                size="mini"
                @click="handleEdit(scope.$index, scope.row)"
                ><i
                  class="iconfont icon-bofanganniu"
                  style="font-size: 31px; color: rgb(96, 98, 102)"
                ></i
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="intro">
          <p>简介</p>
          {{ introduction }}
        </div>
      </el-main>
      <!-- 底部 -->
      <div class="comment">
        <div class="head">
          <h2 style="text-align: left; line-height: 2; margin-bottom: 1px">
            评论
            <span style="color: #999; font-size: 15px"
              >共{{ comment.length }}条多少条评论</span
            >
          </h2>
          <el-form :model="Commentform">
            <el-form-item style="margin-bottom: 6px">
              <el-input
                type="textarea"
                v-model="Commentform.comment"
                placeholder="请输入您的看法"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmitComment1"
                >发表评论</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <div>
          <span style="font-size: 16px; margin-left: 6px">精彩评论</span>
        </div>
        <div
          class="commentList"
          v-for="item in comment.slice().reverse()"
          :key="item['id']"
        >
          <!-- 显示父评论 -->
          <div class="commentContent">
            <div style="float: left; margin-right: 18px">
              <el-image
                style="width: 40px; height: 40px; border-radius: 40px"
                :src="item.avatar"
              ></el-image>
            </div>
            <div class="c_user">
              <div class="name">{{ item.username }}</div>
              <div class="time">{{ item.createTime }}</div>
            </div>
            <div class="pinlun">{{ item.content }}</div>

            <div class="like">
              <i
                @click="likeComment(item.id)"
                class="iconfont icon-31dianzan"
                style="font-size: 19px; color: #333"
              ></i
              ><span
                v-if="item.likes != 0"
                style="margin-left: 5px; color: #333"
                >{{ item.likes }}</span
              >
              <!-- 回复按钮 -->
              <span
                style="color: #6c6868; font-size: 14px; margin-left: 15px"
                class="reback"
                @click="toggleReplyForm(item)"
                >回复</span
              >
              <!-- 删除按钮 -->
              <span
                style="color: #6c6868; font-size: 13px; margin-left: 15px"
                class="del"
                >删除</span
              >
            </div>

            <!-- 回复表单 -->
            <div class="back_comment" v-show="item.showReplyForm">
              <el-form :model="Commentform">
                <el-form-item style="margin-bottom: 6px">
                  <el-input
                    type="textarea"
                    v-model="item.replyForm.comment"
                    placeholder="请输入您的看法"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="onSubmitComment(item)"
                    >发表评论</el-button
                  >
                  <el-button type="primary" @click="cancel(item)"
                    >取消</el-button
                  >
                </el-form-item>
              </el-form>
              <span
                style="color: #6c6868; font-size: 14px; cursor: default"
                @click="selectComment(item)"
                >查看更多回复</span
              >
            </div>

            <!-- 显示父评论的子评论 -->
            <div class="childComments" v-show="item.showReplyForm">
              <div v-for="reply in item.replies" :key="reply.id">
                <!-- 显示子评论内容 -->
                <div class="commentContent">
                  <!-- ...显示子评论内容的代码... -->
                  <div style="float: left; margin-right: 18px">
                    <el-image
                      style="width: 40px; height: 40px; border-radius: 40px"
                      :src="reply.avatar"
                    ></el-image>
                  </div>
                  <div class="c_user">
                    <div class="name">{{ reply.username }}</div>
                    <div class="time">{{ reply.createTime }}</div>
                  </div>
                  <div class="pinlun">{{ reply.content }}</div>

                  <div class="like">
                    <i
                      @click="likeComment(reply.id)"
                      class="iconfont icon-31dianzan"
                      style="font-size: 19px; color: #333"
                    ></i
                    ><span
                      v-if="reply.likes != 0"
                      style="margin-left: 5px; color: #333"
                      >{{ reply.likes }}</span
                    >
                    <!-- 点击回复按钮显示回复表单 -->
                    <span
                      style="color: #6c6868; font-size: 14px; margin-left: 15px"
                      class="reback"
                      @click="toggleReplyForm(reply)"
                      >回复</span
                    >
                  </div>
                </div>
              <div class="separate"></div>

              </div>
            </div>
            <div class="separate"></div>
          </div>
        </div>
      </div>
    </el-container>
  </div>
</template>
    
  <script>
import router from "@/router";
import Cookies from "js-cookie";
export default {
  name: "singerlistcontent",
  data() {
    // 轮播图
    return {
      // showReplyForm: false,
      comment_username: "",
      comment_userImg: "",
      comment_time: "",
      comment_content: "",
      comment: [],
      info: [],
      Commentform: {
        comment: "",
      },
      form: {
        search: "content",
      },
      imgurl: "",
      //songlist的数据
      id: "",
      imgUrl: "",
      title: "",
      playCount: "",
      favCount: "",
      introduction: "",
      tableSongData: [
        {
          songName: "",
          singerName: "",
          albumTitle: "",
          timeInterval: "",
        },
      ],
      url: "",
      currentRow: null,
    };
  },
  mounted() {
    // 获取从index传过来的值
    let id = localStorage.getItem("id");
    this.title = localStorage.getItem("title");
    this.playCount = localStorage.getItem("playCount");
    this.favCount = localStorage.getItem("favCount");
    this.imgUrl = localStorage.getItem("imgUrl");
    this.introduction = localStorage.getItem("introduction");
    this.imgurl = this.imgUrl;
    // 在这个界面发起根据歌单查询歌曲请求
    let url = "https://staryh.me/yin/api/songs/list/" + id;
    console.log(url);
    this.$http.get(url).then((res) => {
      let data = res["data"]["data"];
      // 处理表格数据 将数据进行格式化
      this.tableSongData = data.map((song) => {
        song.songName += " " + song.subtitle; // 将 subtitle 拼接到 songName 后面
        let minutes = Math.floor(parseInt(song.timeInterval) / 60);
        let seconds = parseInt(song.timeInterval) % 60;
        song.timeInterval = `${minutes.toString().padStart(2, "0")}:${seconds
          .toString()
          .padStart(2, "0")}`;
        return song;
      });
    });
    if (Cookies.get("username") != null) {
      let username = Cookies.get("username");
      let login = document.getElementById("judgelogin");
      let url = "https://staryh.me/yin/api/users/info?username=" + username;
      login.style.display = "none";
      this.$http.get(url).then((res) => {
        this.url = res.data.data.userImg;
        this.info = res.data.data;
      });
      this.url = "https://staryh.me/uploads/3.jpg";
    }
    this.getComment(id);
  },
  // 轮播图
  methods: {
    handleChange(index) {
      // eslint-disable-next-line
      console.log(`[Demo 1] You have click cover ${index}`);
    },
    // 导航栏
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    // 跳转到歌手界面
    router1() {
      this.$router.push("/singerlist");
    },
    // 跳转到首页
    shouye() {
      this.$router.push("/index");
    },
    // 我的音乐
    mymusic() {
      this.$router.push("/user");
    },
     // 跳转到mv界面
     myMv() {
      this.$router.push("/mv");
    },
    handleCurrentChange(val) {
      this.currentRow = val;
    },
    rank() {
      this.$router.push("/rank");
    },
    handleEdit(index, row) {
      // 获取参数进行路由转发
      this.currenId = row.id;
      this.tableSongData.songName = row.songName;
      this.tableSongData.albumTitle = row.albumTitle;
      this.tableSongData.timeInterval = row.timeInterval;
      this.url = row.purl;
      console.log(row.id);
      console.log(this.url);
      console.log(this.tableSongData.songName);
      console.log(this.imagePath);
      window.sessionStorage.setItem("singerName", this.tableSongData.singerName);
      // 进行转发
      let url = `/#/play?singerName=${encodeURIComponent(
        this.singerName
      )}&songName=${encodeURIComponent(
        this.tableSongData.songName
      )}&albumTitle=${encodeURIComponent(
        this.tableSongData.albumTitle
      )}&imagePath=${encodeURIComponent(
        this.imagePath
      )}&url=${encodeURIComponent(this.url)}&timeInterval=${encodeURIComponent(
        this.tableSongData.timeInterval
      )}&currentId=${encodeURIComponent(this.currenId)}`;
      window.open(url, "_blank");
    },
    // 评论显示
    toggleReplyForm(item) {
      // 先关闭之前点击的评论的回复表单
      this.comment.forEach((comment) => {
        if (comment !== item) {
          comment.showReplyForm = false;
        }
      });

      // 切换当前点击的评论的回复表单状态
      item.showReplyForm = !item.showReplyForm;
    },
    // 取消按钮
    cancel(item) {
      item.showReplyForm = false;
    },
    onSubmitComment1() {
      let url = "https://staryh.me/yin/api/comment/add";
      let data = {
        username: this.info.username,
        avatar: this.info.userImg,
        userId: this.info.id,
        content: this.Commentform.comment,
        songId: localStorage.getItem("id"),
      };
      this.$http.post(url, data).then((res) => {
        if (res.data.code == 200) {
          console.log(res.data.data);
          this.$message({
            message: "评论成功",
            type: "success",
          });
          // 清空评论
          this.Commentform.comment = "";
          this.getComment(localStorage.getItem("id"));
        } else {
          this.$message({
            message: "评论失败",
            type: "error",
          });
        }
      });
    },

    onSubmitComment(item) {
      // 将回复内容添加到父评论的replies中
      item.replies.push({
        commentId: item.id, // 生成唯一id的函数，你可以根据自己的需求来实现
        username: item.userId, // 替换成实际的用户名
        content: item.replyForm.comment,
        avatar: this.info.userImg,
        username: this.info.username,
        songId: localStorage.getItem("id"),
      });

      // 请求体的数据的封装
      const replyData = {
        commentId: item.id,
        userId: this.info.id,
        username: this.info.username,
        content: item.replyForm.comment,
        avatar: this.info.userImg,
        songId: localStorage.getItem("id"),
      };

      // 发起请求，将回复内容添加到数据库中
      let url_reback = "https://staryh.me/yin/api/sbc/add";
      this.$http.post(url_reback, replyData).then((res) => {
        console.log(res.data);
        if (res.data.code == 200) {
          console.log(res.data.data);
          this.selectComment(item);
          this.$message({
            message: "回复成功",
            type: "success",
          });
        } else {
          this.$message({
            message: "回复失败",
            type: "error",
          });
        }
      });

      // 清空回复表单内容
      item.replyForm.comment = "";
    },
    getComment(id) {
      let url = "https://staryh.me/yin/api/comment/" + id;
      this.$http.get(url).then((res) => {
        this.comment = res.data.data.map((item) => {
          return {
            ...item,
            showReplyForm: false,
            replies: [],
            replyForm: { comment: "" },
          };
        });
      });
    },
    likeComment(id) {
      this.$message({
        message: "该功能还未实现 敬请期待",
        type: "tip",
      });
      this.comment.id = id + 1;
    },

    // 查看评论
    selectComment(item) {
      let url = "https://staryh.me/yin/api/sbc/" + item.id;
      this.$http.get(url).then((res) => {
        console.log(res.data.data);
        if (res.data.data.length == 0) {
          this.$message({
            message: "暂无回复",
            type: "tip",
          });
        }
        item.replies = res.data.data.map((item) => {
          return {
            ...item,
            showReplyForm: false,
          };
        });
      });
    },
  },
};
</script>
    
    
    <style lang="less" scoped>
.el-container {
  padding: 0;
}

.el-header,
.el-footer {
  color: #3333;
  text-align: center;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  min-width: 1200px;
  padding: 0;
}

.el-aside {
  background-color: #d3dce6;
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  width: 1200px;
  min-width: 1200px;
  margin: 0 auto;
  padding: 0%;
  color: #333;
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

.el-menu-demo {
  padding-left: 150px;
}

.search {
  padding: 8px 0;
}

.login {
  // position: relative;
  // margin-left: 460px;
  margin-top: 22px;
  right: -460px;
  cursor: default;
}

.user {
  position: absolute;
  cursor: default;
  left: 400px;
  top: -3px;
}

.el-menu--horizontal > .el-menu-item {
  color: #333;
  font-size: 16px;
}

.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-menu {
  background-color: rgb(249, 249, 249);
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
  height: 240px;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
  font-weight: 400;
  color: #606266;
  cursor: default;
}
.div .el-menu--horizontal > .el-menu-item {
  color: #333;
  font-size: 14px;
  padding-right: 20px;
}
.div_1 {
  width: 100%;
}
.div .div_1 .el-menu.el-menu--horizontal {
  margin-left: 400px;
}
.div {
  width: 100%;
}
.main_header {
  width: 1200px;
  height: 250px;
  margin-top: 40px;
  //   background-color: pink;
}
.img {
  float: left;
  width: 250px;
  height: 250px;
  margin: 0 10px 20px;
}
.content {
  float: left;
  margin-left: -5px;
  height: 250px;
  width: 900px;
  //   background-color: skyblue;
}
.c_content {
  height: 50px;
  width: 880px;
}

.main_c {
  margin-top: 20px;
}
.el-table__body-wrapper {
  color: #333;
  overflow: hidden;
  position: relative;
}
.fav {
  margin-left: 21px;
  margin-bottom: 6px;
}
.play {
  margin-left: 21px;
  margin-bottom: 6px;
}
.type {
  margin-left: 21px;
  margin-bottom: 6px;
}
.intro {
  color: #000;
  font-size: 14px;
  margin-left: 10px;
}
.el-table .el-table__cell .cell {
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-all;
  line-height: 23px;
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap; /* 不换行 */
  overflow: hidden; /* 溢出隐藏 */
  text-overflow: ellipsis; /* 显示省略号 */
}
.el-avatar {
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
  color: #fff;
  width: 60px;
  height: 60px;
  line-height: 40px;
  background: none;
}
.comment {
  width: 1050px;
  margin-top: 20px;
  margin-left: 242px;
}
/deep/ .el-textarea__inner {
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 92%;
  font-size: inherit;
  color: #606266;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  height: 138px;
}
/deep/ .el-button {
  font-size: 12px;
  margin-top: -5px;
}
.c_user {
  color: #999;
  font-size: 13px;
}
.pinlun {
  color: #000;
  font-size: 14px;
  margin-top: 10px;
  margin-left: 56px;
}
.commentContent {
  margin-top: 10px;
}
.separate {
  width: 965px;
  margin-top: 10px;
  border-bottom: 1px solid #ededed;
}
.like {
  margin-left: 56px;
  margin-top: 8px;
}
.del {
  display: none;
}
.playall {
  margin-left: 20px;
  margin-top: 37px;
  font-size: 15px;
}
.playall .el-button {
  font-size: 15px;

}
.reback {
  cursor: default;
}
.reback:hover {
  color: rgb(0, 179, 255) !important;
}

.icon-31dianzan:hover {
  color: rgb(0, 179, 255) !important; /* 设置鼠标经过时的图标颜色 */
}
.back_comment {
  margin-left: 56px;
  margin-top: 10px;
  // display: none;
}
.childComments {
  margin-left: 56px;

}

</style>
    