<template>
  <!-- 轮播图 -->
  <div id="app">
    <el-container>
      <!-- 头部 -->
      <el-header>
        <div class="head_1">
          <el-menu class="el-menu-demo" mode="horizontal">
            <el-menu-item index="1"
           
              >  
              <span style="font-size: 40px;color: #909399">star</span>
              <i
                class="iconfont icon-yinletai"
                style="font-size: 52px; color: #909399"
              ></i
            ></el-menu-item>
            <el-menu-item index="1">首页</el-menu-item>
            <el-menu-item index="1" @click="router1">歌手</el-menu-item>
            <el-menu-item index="1" @click="myMv">Mv</el-menu-item>
            <el-menu-item index="1" @click="rank">排行榜</el-menu-item>
            <el-menu-item index="1" @click="mymusic">我的音乐</el-menu-item>
            <el-menu-item index="1"
            @click="chat"
           >  
           <i
             class="iconfont icon-jiqiren"
             style="font-size: 38px;"
           ></i
         ></el-menu-item>
            <el-menu-item index="1">
              <!-- 搜索 -->
              <el-form ref="form" :model="form" label-width="0px">
                <el-form-item class="search">
                  <el-input
                    v-model="form.content"
                    placeholder="歌手 歌单 歌曲"
                    size="medium"
                  >
                    <template v-slot:suffix>
                      <i
                        class="el-icon-search"
                        @click="handleIconClick"
                        style="cursor: pointer"
                      ></i>
                    </template>
                  </el-input>
                </el-form-item>
              </el-form>
            </el-menu-item>
            <!-- 登录 -->
            <el-menu-item index="1">
              <el-breadcrumb separator="/">
                <el-breadcrumb-item
                  :to="{ path: '/login' }"
                  class="login"
                  style="cursor: default"
                  ><span style="cursor: default" id="judgelogin"
                    >登录</span
                  ></el-breadcrumb-item
                >
              </el-breadcrumb>
            </el-menu-item>
            <!-- 管理员显示 -->
            <!-- <el-menu-item>
              <div class="admin">
                <span
                  style="font-size: 14px; color: red"
                  id="judgeAdmin"
                  @click="admin"
                  >管理员</span
                >
              </div>
            </el-menu-item> -->
            <!-- 头像 -->
            <el-popover placement="top-start" width="200" trigger="hover">
              <div class="hide">
                <ul>
                  <li @click="mymusic">我的资料</li>
                  <li @click="exit">退出登录</li>
                </ul>
              </div>
              <div slot="reference" class="user" @click="mymusic">
                <el-avatar :src="url"></el-avatar>
              </div>
            </el-popover>
            <!-- <el-menu-item>
            <div class="user" @click="mymusic">
              <el-avatar :src="url"></el-avatar>
            </div>
          </el-menu-item> -->
          </el-menu>
        </div>
      </el-header>
      <!-- 中间部分 -->
      <el-main>
        <h2 style="line-height: 70px">推荐歌手</h2>
        <div class="flow_re">
          <coverflow
            :coverList="coverList"
            :coverWidth="260"
            :index="2"
            style="width: 845px; margin-left: 35px"
          ></coverflow>
          <el-popover placement="right" trigger="click">
            <div class="div" style="width: 200px" v-for="(item,index) in songTop" :key="item.id" @click="routerSong(item)">
              <div class="re_song_1">
                <div class="rank_song">{{ index+1 }}</div>
                <div class="singer_name_song">
                  <span>{{item.songName}}</span>
                  <span>-</span>
                   <span>{{item.singerName}}</span>
                </div>
              </div>
            </div>
            <div class="re_song" slot="reference">
              <div class="th">每日推荐</div>
              <div class="calendar">
                <i
                  class="iconfont icon-rili"
                  style="font-size: 100px; color: #333"
                ></i>
              </div>
              <div class="number_date">
                {{ todayDate }}
              </div>
            </div>
          </el-popover>
        </div>

        <h2 style="text-align: left; margin-left: 30px">推荐MV</h2>
        <span class="more" @click="router_moremv">更多</span>
        <!-- 推荐MV -->
        <div class="mv">
          <template>
            <div class="block">
              <el-carousel height="500px">
                <el-carousel-item v-for="item in mvTop5" :key="item.id">
                  <el-image
                    @click="routerMv(item.id)"
                    :src="item.imgSrc"
                    style="width: 100%; height: 500px"
                  ></el-image>
                  <h3 class="small">{{ item.title }}</h3>
                </el-carousel-item>
              </el-carousel>
            </div>
            <div class="rank">
              <!-- <span>本周排行榜</span> -->

              <div
                class="rank_1"
                style="display: flex"
                v-for="(item, index) in mvTop5"
                :key="item.id"
                @click="routerMv(item.id)"
              >
                <div
                  style="
                    color: red;
                    width: 60px;
                    height: 80px;
                    line-height: 80px;
                    font-size: 24px;
                    font-weight: bold;
                  "
                >
                  {{ index + 1 }}
                </div>
                <el-image
                  :src="item.imgSrc"
                  style="width: 100px; height: 80px; border-radius: 10px"
                ></el-image>
                <div class="div1" style="margin-left: 10px; margin-top: 10px">
                  <div
                    class="title"
                    style="text-align: left;margin-top: 8px;color: #000;!important"
                  >
                    {{ item.title }}
                  </div>
                  <div
                    class="singername"
                    style="text-align: left;margin-top: 10px;color: #000;!important"
                  >
                    {{ item.singer }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>

        <!-- 推荐歌单 -->
        <h2>推荐歌单</h2>
        <!-- 动态生成推荐歌单 -->
        <el-row
          :gutter="20"
          style="margin-left: 0; margin-right: 0; height: 224px"
        >
          <el-col v-for="(img, index) in imgUrl" :key="index" :span="6">
            <div
              class="grid-content bg-purple"
              @click="
                goToSong(
                  id[index],
                  title[index],
                  playCount[index],
                  favCount[index],
                  imgUrl[index],
                  introduction[index]
                )
              "
              style="height: 224px"
            >
              <el-image
                style="width: 224px; height: 224px"
                :src="img"
              ></el-image>
            </div>
            <div class="song_title">{{ title[index] }}</div>
            <div class="playcount">播放量: {{ playCount[index] }}</div>
          </el-col>
        </el-row>
      </el-main>
      <!-- 底部 -->
      <el-footer>
        <!-- audio element -->
        <vue-plyr ref="plyr">
          <audio controls crossorigin playsinline>
            <source :src="musicUrl" type="audio/mp3" />
            <source :src="audioUrl" type="audio/ogg" />
          </audio>
        </vue-plyr>
        <div class="switch">
          <!-- 下一首 -->
          <div class="next" @click="next">
            <i
              class="iconfont icon-1_music82"
              style="font-size: 31px; color: #333"
            ></i>
          </div>
          <!-- 上一首 -->
          <div class="pre" @click="pre">
            <i
              class="iconfont icon-1_music83"
              style="font-size: 31px; color: #333"
            ></i>
          </div>
          <div class="icon">
            <i
              class="iconfont icon-music1"
              style="font-size: 36px; color: #333"
            ></i>
          </div>

          <div class="list" @click="drawer = true">
            <i
              class="iconfont icon-yinleliebiao-"
              style="font-size: 31px; color: #333"
            ></i>
          </div>

          <div class="love" @click="getSong">
            <i
              id="loveFont"
              class="iconfont icon-xiai"
              style="font-size: 31px; color: #333"
            ></i>
          </div>
          <div class="song">
            <span id="moren">她说 林俊杰</span>
            {{ tableSongData.songname }} {{ tableSongData.singerName }}
          </div>
        </div>
      </el-footer>
    </el-container>
    <el-drawer
      title=""
      :visible.sync="drawer"
      :with-header="false"
      class="songlist"
    >
      <h2 style="font-style: italic; color: #606266; margin-left: 5px">
        播放队列
      </h2>
      <el-table ref="singleTable" :data="tableSongData" style="width: 100%">
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column property="songName" label="歌曲" width="0">
        </el-table-column>

        <el-table-column
          property="singerName"
          label="歌手"
          width="0"
          align="center"
        >
        </el-table-column>
        <el-table-column property="timeInterval" label="时长" align="center">
        </el-table-column>
        <el-table-column label="">
          <template slot-scope="scope">
            <el-button
              size="mini"
              style="border: none"
              @click="handleEdit(scope.$index, scope.row)"
            >
              <i
                class="iconfont icon-bofanganniu"
                style="font-size: 31px; color: "
              ></i
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-drawer>
  </div>
</template>

<script>
import router from "@/router";
import Cookies from "js-cookie";
export default {
  name: "index",

  data() {
    // 轮播图
    return {
      todayDate: new Date().getDate(),
      // mv前五
      mvTop5: [],
      // loveid显示
      loveid: "",
      // 歌单列表的数据绑定对象
      id: [],
      imgUrl: [],
      title: [],
      playCount: [],
      favCount: [],
      introduction: [],
      url: "",
      musicUrl: "",
      audioUrl: "",
      currentId: "",
      clickCount: 0,
      tableSongData: [
        {
          songName: "",
          singerName: "",
          timeInterval: "",
        },
      ],
      coverList: [
        {
          cover: require("../assets/images/jl.jpg"),
          title: "周杰伦",
        },
        {
          cover: require("../assets/images/ljj.jpg"),
          title: "林俊杰",
        },
        {
          cover: require("../assets/images/dzq.jpg"),
          title: "邓紫棋",
        },
        {
          cover: require("../assets/images/wsl.jpg"),
          title: "汪苏泷",
        },
        {
          cover: require("../assets/images/syc.jpg"),
          title: "单依纯",
        },
        {
          cover: require("../assets/images/xzq.jpg"),
          title: "薛之谦",
        },
        {
          cover: require("../assets/images/ycl.jpg"),
          title: "杨丞琳",
        },
        {
          cover: require("../assets/images/syz.jpg"),
          title: "孙燕姿",
        },
      ],

      form: {
        search: "content",
      },
      drawer: false,
      userid: "",
      songTop: [],
    };
  },
  // 定义钩子函数
  mounted() {
    this.$http.get("https://staryh.me/yin/api/songs/list").then((res) => {
      let data = res["data"]["data"];
      // 声明数组存放data域里面的值
      let ids = [];
      let titles = [];
      let imgurls = [];
      let playcopunts = [];
      let favcounts = [];
      let introductions = [];
      for (let item of data) {
        ids.push(item.id);
        titles.push(item.title);
        imgurls.push(item.imgUrl);
        playcopunts.push(item.plyCount);
        favcounts.push(item.favCount);
        introductions.push(item.introduction);
      }
      this.imgUrl = imgurls;
      this.title = titles;
      this.playCount = playcopunts;
      this.favCount = favcounts;
      this.id = ids;
      this.introduction = introductions;
    });

    // 判断是否为管理员
    // if (Cookies.get("username") != "admin") {
    //   let username = Cookies.get("username");
    //   let admin = document.getElementById("judgeAdmin");
    //   admin.style.display = "none";
    // }
    // 判断是否登录
    if (Cookies.get("username") != null) {
      let username = Cookies.get("username");
      let login = document.getElementById("judgelogin");
      let user = document.querySelector(".user");
      let url = "https://staryh.me/yin/api/users/info?username=" + username;
      login.style.display = "none";
      user.style.display = "block";
      this.$http.get(url).then((res) => {
        if(res.data.data.userImg == null)
          this.url = "https://img.zcool.cn/community/0165cb5d14565ca8012155290a6d86.png@1280w_1l_2o_100sh.png";
        else
          this.url = res.data.data.userImg;
      });
    }

    // 在这个界面发起根据歌单查询歌曲请求
    async function fetchData() {
      let requests = [];
      for (let id = 1; id <= 8; id++) {
        let url = "https://staryh.me/yin/api/songs/list/" + id;
        requests.push(this.$http.get(url));
      }

      let responses = await Promise.all(requests);

      responses.forEach((res) => {
        let data = res.data.data;
        let formattedData = data.map((song) => {
          let minutes = Math.floor(parseInt(song.timeInterval) / 60);
          let seconds = parseInt(song.timeInterval) % 60;
          song.timeInterval = `${minutes.toString().padStart(2, "0")}:${seconds
            .toString()
            .padStart(2, "0")}`;
          return song;
        });
        this.tableSongData = this.tableSongData.concat(formattedData);
      });
    }

    fetchData.call(this);

    // 默认加载她说

    this.musicUrl = "https://staryh.me/music/music_list/她说.mp3";
    this.currentId = 14;
    // 自动切换歌曲
    this.$refs.plyr.player.on("ended", () => {
      this.next();
    });

    // 请求前五mv
    this.getMV();

    // 查询播放量前十歌曲
    this.selectSongTop();
  },
  // 重新加载musicurl
  watch: {
    musicUrl() {
      this.$refs.plyr.player.source = {
        type: "audio",
        title: "Example title",
        sources: [
          {
            src: this.musicUrl,
            type: "audio/mp3",
          },
        ],
      };
    },
  },

  // 轮播图
  methods: {
    // 搜索表单验证
    handleIconClick() {
      this.$router.push({
        name: "search",
        params: {
          content: this.form.content,
        },
      });
    },
    // 跳转到歌手界面
    router1() {
      this.$router.push("/singerlist");
    },
    // 我的音乐
    mymusic() {
      this.$router.push("/user");
    },
    admin() {
      this.$router.push("/admin");
    },
    // 跳转到mv界面
    myMv() {
      this.$router.push("/mv");
    },
    // 退出登录
    exit() {
      Cookies.remove("username");
      Cookies.remove("token");
      this.$router.push("/login");
    },
    router_moremv() {
      this.$router.push("/mv");
    },
    rank() {
      this.$router.push("/rank");
    },
    chat() {
      // 跳转到百度
      window.open("https://www.chatying.top/", "_blank");
    },
    // 每个歌单所对应的详情页
    goToSong(id, title, playCount, favCount, imgUrl, introduction) {
      localStorage.setItem("id", id);
      localStorage.setItem("title", title);
      localStorage.setItem("playCount", playCount);
      localStorage.setItem("favCount", favCount);
      localStorage.setItem("imgUrl", imgUrl);
      localStorage.setItem("introduction", introduction);
      // 使用编程式导航跳转到 songlist 页面
      this.$router.push({
        name: "songlist",
        params: {
          imgUrl: imgUrl,
        },
      });
    },
    handleEdit(index, row) {
      // 传递id的值
      this.loveid = row.id + 1;
      this.currentId = row.id;
      let moren = document.querySelector("#moren");
      moren.style.display = "none";
      this.tableSongData.songname = row.songName;
      this.tableSongData.singerName = row.singerName;
      let url = "https://staryh.me/yin/api/songs/listUrl/" + row.id;
      this.$http.get(url).then((res) => {
        this.musicUrl = res.data.data.purl;
        this.$nextTick(() => {
          this.loveid = this.loveid + 1;
          this.$refs.plyr.player.play(); // 播放音频
          this.drawer = false;
        });
        // console.log(this.musicUrl);
      });
    },
    // 下一首
    next() {
      this.loveid = this.currentId + 1;
      let nexturl =
        "https://staryh.me/yin/api/songs/url/next/" + this.currentId;
      this.$http.get(nexturl).then((res) => {
        this.musicUrl = res.data.data.purl;
        let moren = document.querySelector("#moren");
        moren.style.display = "none";
        this.tableSongData.songname = res.data.data.songName;
        this.tableSongData.singerName = res.data.data.singerName;
        this.currentId = res.data.data.id;
        this.$nextTick(() => {
          this.$refs.plyr.player.play(); // 播放音频
        });
      });
    },
    //上一首
    pre() {
      this.loveid = this.currentId;
      let preurl = "https://staryh.me/yin/api/songs/url/up/" + this.currentId;
      this.$http.get(preurl).then((res) => {
        this.musicUrl = res.data.data.purl;
        let moren = document.querySelector("#moren");
        moren.style.display = "none";
        this.tableSongData.songname = res.data.data.songName;
        this.tableSongData.singerName = res.data.data.singerName;
        this.currentId = res.data.data.id;
        this.$nextTick(() => {
          this.$refs.plyr.player.play(); // 播放音频
        });
      });
    },

    // 获取当前播放的相关信息
    getSong() {
      if (Cookies.get("username") == null) {
        this.$message({
          message: "请先登录",
          type: "warning",
        });
        this.$router.push("/login");
      }
      // 查询用户id
      let username = Cookies.get("username");
      let userurl =
        "https://staryh.me/yin/api/users/info?username=" + username;
      this.$http.get(userurl).then((res) => {
        this.userid = res.data.data.id;
        // console.log(this.userid);
      });
      // 添加信息到喜欢表单
      let url = "https://staryh.me/yin/api/songs/url/up/" + this.loveid;
      this.$http.get(url).then((res) => {
        // console.log(res.data.data);

        this.$http
          .post("https://staryh.me/yin/api/songs/addSongToList", {
            userId: this.userid,
            songId: res.data.data.id,
            songName: res.data.data.songName,
            singerName: res.data.data.singerName,
            purl: res.data.data.purl,
            timeInterval: res.data.data.timeInterval,
            subtitle: res.data.data.subtitle,
            albumTitle: res.data.data.albumTitle,
          })
          .then((res) => {
            // console.log(res.data);
            if (res.data.code != 200) {
              this.$message({
                message: "已经添加过了",
                type: "warning",
              });
            } else {
              this.$message.success("添加成功");
            }
          });
      });
    },
    // 请求mv
    getMV() {
      function formatDate(timestamp) {
        const date = new Date(timestamp * 1000);
        const year = date.getFullYear();
        const month = ("0" + (date.getMonth() + 1)).slice(-2);
        const day = ("0" + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
      }
      let url = "https://staryh.me/yin/api/mv/all";
      this.$http.get(url).then((res) => {
        this.mvTop5 = res.data.data;
        this.mvTop5.forEach((item) => {
          item.time = formatDate(item.time);
        });
      });
    },

    // 跳转到mv界面
    routerMv(id) {
      let url = `/#/mvPlay?mvId=${encodeURIComponent(id)}`;

      window.open(`${window.location.origin}${url}`, "_blank");
    },
      // 查询播放量前十歌曲
      selectSongTop() {
      let url = "https://staryh.me/yin/api/songs/playCountTop";
      this.$http.get(url).then((res) => {
        this.songTop = res.data.data;
        this.songTop.reverse();
      });
    },

    routerSong(item){
      let url = `/#/play?singerName=${encodeURIComponent(
        window.sessionStorage.setItem("singerName",item.singerName)
      )}&songName=${encodeURIComponent(
        item.songName
      )}&albumTitle=${encodeURIComponent(
        item.albumTitle
      )}&imagePath=${encodeURIComponent(
        item.singerPhoto
      )}&url=${encodeURIComponent(this.url)}&timeInterval=${encodeURIComponent(
        item.timeInterval
      )}&currentId=${encodeURIComponent(
        item.id
      )}&identify=${encodeURIComponent(1)}`;
      window.open(url, "_blank");
    }
  },
};
</script>

<style lang="less" scoped>
.el-container {
  padding: 0;
}

.el-header,
.el-footer {
  position: relative;
  color: #3333;
  text-align: center;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  min-width: 1200px;
  padding: 0;
}
.el-footer {
  box-shadow: none !important;
}
.el-aside {
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  width: 1200px;
  min-width: 1200px;
  margin: 0 auto;
  padding: 0%;
  color: #333;
  text-align: center;
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

.el-menu-demo {
  padding-left: 150px;
}

.search {
  padding: 8px 0;
}

.login {
  // position: relative;
  // margin-left: 460px;
  margin-top: 22px;
  right: -460px;
  cursor: default;
}

.user {
  display: none;
  position: absolute;
  cursor: default;
  left: 1200px;
  top: -3px;
}

.el-menu--horizontal > .el-menu-item {
  color: #333;
  font-size: 16px;
}

.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
}
.bg-purple {
  height: 240px;
}
.bg-purple-light {
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
}
.el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
  font-weight: 400;
  color: #606266;
  cursor: default;
}
.song_title {
  margin-top: 5px;
  width: 224px;
  color: #000;
  font-size: 14px;
  text-align: left;
  margin-left: 24px;
}
.playcount {
  width: 224px;
  width: 224px;
  color: #999;
  font-size: 14px;
  text-align: left;
  margin-left: 24px;
}
.bg-purple[data-v-47323bf2] {
  background: none;
  height: opx;
}
.el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
  font-weight: 400;
  color: #606266;
  cursor: default !important;
}
.el-avatar {
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
  color: #fff;
  width: 60px;
  height: 60px;
  line-height: 40px;
  background: none;
}

/deep/ .plyr__controls {
  position: relative;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center;
}

/deep/ .plyr .plyr__controls {
  background-color: bga(249, 249, 249);
  background: var(--plyr-audio-controls-background, bga(249, 249, 249));
  border-radius: inherit;
  color: #4a5464;
  color: var(--plyr-audio-control-color, #4a5464);
  padding: 10px;
  padding: var(--plyr-control-spacing, 10px);
}
/deep/ .plyr__controls .plyr__controls__item.plyr__progress__container {
  width: 100%;
  padding-left: calc(10px / 4);
  padding-left: calc(var(--plyr-control-spacing, 10px) / 4);
  margin-top: -36px;
}
/deep/ .plyr__controls .plyr__controls__item:first-child {
  position: absolute;
  left: 795px;
  top: 24px;
  margin-left: 0;
  margin-right: auto;
  color: #333;
}
.next {
  float: left;
  position: absolute;
  left: 875px;
  top: -35px;
}
.next:hover {
  background-color: rgb(0, 179, 255);
}
.pre:hover {
  background-color: rgb(0, 179, 255);
}
.love i.icon-xiai:hover {
  color: red !important;
}
.pre {
  position: absolute;
  float: left;
  left: 720px;
  top: -36px;
}
.icon {
  position: absolute;
  float: left;
  left: 0;
  top: -40px;
}

.list {
  position: absolute;
  float: left;
  left: 400px;
  top: -36px;
}

.love {
  position: absolute;
  float: left;
  left: 500px;
  top: -36px;
}
.song {
  position: absolute;
  font-weight: bold;
  font-style: italic;
  color: #333;
  float: left;
  left: 60px;
  top: -35px;
}
.switch {
  position: relative;
}
/deep/ .el-drawer {
  width: 25% !important;
  position: absolute;
  box-sizing: border-box;
  background-color: rgb(249, 249, 249);
  display: flex;
  flex-direction: column;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
    0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  outline: 0;
}

/deep/ [data-v-47323bf2] .plyr__controls .plyr__controls__item.plyr__time {
  left: 155px;
  position: relative;
  margin-top: -29px;
  padding: 0 calc(10px / 2);
  padding: 0 calc(var(--plyr-control-spacing, 10px) / 2);
}
/deep/ .plyr__menu {
  display: flex;
  position: absolute;
  top: 24px;
  // right: 524px;
  right: 50px;
}

/deep/
  [data-v-47323bf2]
  [data-v-47323bf2]
  .plyr__controls
  .plyr__controls__item.plyr__time {
  font-weight: bold;
  font-size: 16px;
  color: #333;
  position: absolute;
  padding: 0 calc(10px / 2);
  top: 51px;
  padding: 0 calc(var(--plyr-control-spacing, 10px) / 2);
}
/deep/ .plyr__volume {
  align-items: center;
  /* display: flex; */
  max-width: 110px;
  min-width: 80px;
  position: absolute;
  width: 20%;
  top: 24px;
  // right: 570px;
  right: 100px;
}
/deep/ .plyr__controls .plyr__controls__item.plyr__progress__container {
  margin-right: -10px;
  margin-left: -44px;
  width: 100%;
  padding-left: calc(10px / 4);
  padding-left: calc(var(--plyr-control-spacing, 10px) / 4);
  margin-top: -15px;
}

.hide {
  width: 150px;
}
.hide ul li {
  list-style: none;
  margin-top: 10px;
  margin-left: 10px;
  font-size: 18px;
  color: #333;
  cursor: default;
}

.hide ul li:hover {
  color: rgb(0, 179, 255);
}
el-menu-item:hover {
  color: rgb(0, 179, 255);
}

/* 设置鼠标经过时的背景颜色 */
.el-menu--horizontal > .el-menu-item:hover {
  color: rgb(0, 179, 255) !important;
}

/* 设置鼠标经过时的文字颜色 */
.el-menu--horizontal > .el-menu-item:hover > a,
.el-menu--horizontal > .el-menu-item.is-active > a {
  color: rgb(0, 179, 255) !important;
}

// mv
.el-carousel__item h3 {
  color: #000;
  font-size: 18px;
  opacity: 0.75;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
}

.el-carousel__item:nth-child(2n + 1) {
}
.mv {
  margin-left: 36px;
  display: flex;
}
.block {
  width: 838px;
  margin-bottom: 20px;
}
.rank {
  // display: flex;
  width: 300px;
  height: 500px;
  color: #000;
  font-size: 18px;
  margin-left: 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.more {
  position: relative;
  top: -42px;
  right: -538px;
  color: #000;
  font-size: 17px;
  font-weight: bold;
  cursor: default;
}
.more:hover {
  color: rgb(0, 179, 255);
}
.rank_1 {
  width: 300px;
  height: 80px;
  margin-top: 19px;
}
.div1 {
  width: 200px;
  height: 80px;
  margin-left: 10px;
  margin-top: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
}
.singername {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
}
.title:hover,
.singername:hover {
  color: rgb(0, 179, 255) !important;
}
.re_song {
  position: relative;
  top: -16px;
  width: 280px;
  height: 298px;
  margin-left: 20px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  // 渐变色背景
  background-image: linear-gradient(
    to right,
    #e4afcb 0%,
    #b8cbb8 0%,
    #b8cbb8 0%,
    #e2c58b 30%,
    #c2ce9c 64%,
    #7edbdc 100%
  );
}
.flow_re {
  display: flex;
}
.th {
  width: 280px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  margin-left: 20px;
  margin-top: 20px;
  cursor: default;
}
.calendar {
  position: absolute;
  margin: auto;
  cursor: default;
  top: 35%;
  left: 29%;
}
.number_date {
  position: absolute;
  margin: auto;
  cursor: default;
  width: 50px;
  height: 50px;
  top: 47%;
  left: 39%;
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  // color: #6b6464;
}
.re_song_1{
  display: flex;
}
.rank_song{
  width: 40px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: rgb(0, 179, 255) !important;
  cursor: default;
}
.singer_name_song{
  width: 200px;
  height: 30px;
  line-height: 30px;
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
}
.singer_name_song:hover,
.re_song_1:hover{
  color: rgb(0, 179, 255) !important;
}
</style>

<!-- 

#安装acme：
curl https://get.acme.sh | sh
#安装socat：
yum install socat
#添加软链接：
ln -s  /root/.acme.sh/acme.sh /usr/local/bin/acme.sh
#切换CA机构： 
acme.sh --set-default-ca --server letsencrypt
#申请证书： 
acme.sh  --issue -d staryh.me --standalone -k ec-256
#安装证书： 
acme.sh --installcert -d staryh.me --ecc  --key-file  /home/ssl/server.key   --fullchain-file  /home/ssl/server.crt   -->