<template>
  <!-- 轮播图 -->
  <div id="app">
    <el-container>
      <!-- 头部 -->
      <el-header>
        <el-menu class="el-menu-demo" mode="horizontal">
          <el-menu-item index="1"
           
           >  
           <span style="font-size: 40px;color: #909399">star</span>
           <i
             class="iconfont icon-yinletai"
             style="font-size: 52px; color: #909399"
           ></i
         ></el-menu-item>

          <el-menu-item index="1" @click="index">首页</el-menu-item>
          <el-menu-item index="1" @click="router1">歌手</el-menu-item>
          <el-menu-item index="1">Mv</el-menu-item>
          <el-menu-item index="1" @click="rank">排行榜</el-menu-item>
          <el-menu-item index="1" @click="mymusic">我的音乐</el-menu-item>
          <el-menu-item index="1">
            <!-- 搜索 -->
            <el-form ref="form" :model="form" label-width="0px">
              <el-form-item class="search">
                <el-input
                  v-model="form.content"
                  placeholder="歌手 歌单 歌曲"
                  size="medium"
                >
                  <template v-slot:suffix>
                    <i
                      class="el-icon-search"
                      @click="handleIconClick"
                      style="cursor: pointer"
                    ></i>
                  </template>
                </el-input>
              </el-form-item>
            </el-form>
          </el-menu-item>
          <!-- 登录 -->
          <el-menu-item index="1">
            <el-breadcrumb separator="/">
              <el-breadcrumb-item
                :to="{ path: '/login' }"
                class="login"
                style="cursor: default"
                ><span style="cursor: default" id="judgelogin"
                  >登录</span
                ></el-breadcrumb-item
              >
            </el-breadcrumb>
          </el-menu-item>
          <!-- 管理员显示 -->
          <!-- <el-menu-item>
            <div class="admin">
              <span
                style="font-size: 14px; color: red"
                id="judgeAdmin"
                @click="admin"
                >管理员</span
              >
            </div>
          </el-menu-item> -->
          <!-- 头像 -->
          <el-popover placement="top-start" width="200" trigger="hover">
            <div class="hide">
              <ul>
                <li @click="mymusic">我的资料</li>
                <li @click="exit">退出登录</li>
              </ul>
            </div>
            <div slot="reference" class="user" @click="mymusic">
              <el-avatar :src="url"></el-avatar>
            </div>
          </el-popover>
        </el-menu>
      </el-header>
      <!-- 中间部分 -->
      <el-main>
        <div class="main">
          <div class="rea_tag" style="margin-bottom: -10px">
            <ul>
              <li>区域</li>
              <li class="move all" id="15" @click="select(15)">全部</li>
              <li class="move" id="16" @click="select(16)">内地</li>
              <li class="move" id="17" @click="select(17)">港台</li>
              <li class="move" id="18" @click="select(18)">欧美</li>
              <li class="move" id="19" @click="select(19)">韩国</li>
              <li class="move" id="20" @click="select(20)">日本</li>
            </ul>
          </div>
          <div class="version_tag">
            <ul>
              <li>版本</li>
              <li class="move allin" id="7" @click="select_ver(7)">全部</li>
              <li class="move" id="8" @click="select_ver(8)">MV</li>
              <li class="move" id="9" @click="select_ver(9)">现场</li>
              <li class="move" id="10" @click="select_ver(10)">翻唱</li>
              <li class="move" id="11" @click="select_ver(11)">舞蹈</li>
              <li class="move" id="12" @click="select_ver(12)">影视</li>
              <li class="move" id="13" @click="select_ver(13)">综艺</li>
              <li class="move" id="14" @click="select_ver(14)">儿歌</li>
            </ul>
          </div>
          <div class="content">
            <div class="part">
              <span>{{ areaName }}</span>
              <span>{{ versionName }}</span>
            </div>

            <div class="mv_content">
              <el-row :gutter="20">
                <el-col
                  :span="6"
                  v-for="item in mv"
                  :key="item.id"
                  class="mv-item"
                  ><div
                    class="grid-content bg-purple"
                    @click="routerPlay(item.id)"
                  >
                    <div class="demo-image__lazy">
                      <el-image :src="item.imgSrc" lazy></el-image>
                    </div>
                    <div class="bofang">
                      <i
                        class="iconfont icon-bofang1"
                        style="font-size: 55px; color: #fff"
                      ></i>
                    </div>
                    <div class="title">{{ item.title }}</div>
                    <div class="singer">{{ item.singer }}</div>
                    <div class="detail">
                      <i
                        class="iconfont icon-zhibo"
                        style="font-size: 20px; color: #989393"
                      ></i>
                      <span style="margin-left: 5px">{{ item.playCount }}</span>
                      <span>{{ item.time }}</span>
                    </div>
                  </div></el-col
                >
              </el-row>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
  
  <script>
import router from "@/router";
import Cookies from "js-cookie";
export default {
  name: "index",

  data() {
    return {
      area_id: "",
      version_id: "",
      url: "",
      areaName: "",
      versionName: "",
      mv: [],
      imgurl:
        "http://y.gtimg.cn/music/photo_new/T015R640x360M000002ooIxS2PisWV.jpg",
      // 搜索
      form: {
        search: "content",
      },
    };
  },
  // 定义钩子函数
  mounted() {
    let all = document.querySelector(".all");
    all.style.color = "rgb(0, 179, 255)";
    let allin = document.querySelector(".allin");
    allin.style.color = "rgb(0, 179, 255)";
    // 判断是否为管理员
    // if (Cookies.get("username") != "admin") {
    //   let username = Cookies.get("username");
    //   let admin = document.getElementById("judgeAdmin");
    //   admin.style.display = "none";
    // }
    // 判断是否登录
    if (Cookies.get("username") != null) {
      let username = Cookies.get("username");
      let login = document.getElementById("judgelogin");
      let url = "https://staryh.me/yin/api/users/info?username=" + username;
      login.style.display = "none";
      this.$http.get(url).then((res) => {
        this.url = res.data.data.userImg;
      });
    }
    // 默认加载全部模块
    this.selectAll(15, 7);
  },

  // 轮播图
  methods: {
    // 搜索表单验证
    handleIconClick() {
      this.$router.push({
        name: "search",
        params: {
          content: this.form.content,
        },
      });
    },
    // 跳转到歌手界面
    router1() {
      this.$router.push("/singerlist");
    },
    // 跳转到首页
    index() {
      this.$router.push("/index");
    },

    // 我的音乐
    mymusic() {
      this.$router.push("/user");
    },
    admin() {
      this.$router.push("/admin");
    },
    // 退出登录
    exit() {
      Cookies.remove("username");
      this.$router.push("/login");
    },
    // 跳转到排行榜
    rank() {
      this.$router.push("/rank");
    },

    // 点击对应区域的数据获取
    select(id) {
      // 获取li里面的值
      let area = document.getElementById(id);
      // 将所有选项的颜色都设为原始颜色
      let lis = document.querySelectorAll(".rea_tag li");
      for (let i = 0; i < lis.length; i++) {
        lis[i].style.color = "#333";
      }
      this.areaName = area.innerHTML;
      this.selectAll(id, this.version_id);
      this.area_id = id;
      area.style.color = "rgb(0, 179, 255)";
    },
    // 点击对应版本的数据获取
    select_ver(id) {
      // 获取li里面的值
      let version = document.getElementById(id);
      // 将所有选项的颜色都设为原始颜色
      let lis = document.querySelectorAll(".version_tag li");
      for (let i = 0; i < lis.length; i++) {
        lis[i].style.color = "#333";
      }
      this.versionName = version.innerHTML;
      this.selectAll(this.area_id, id);
      this.version_id = id;
      version.style.color = "rgb(0, 179, 255)";
    },

    // 查询对应的mv数据
    selectAll(areaIndex, versionIndex) {
      let url = "";
      if (areaIndex === "") {
        url = "https://staryh.me/yin/api/mv?" + "version=" + versionIndex;
        console.log(url);
      } else if (versionIndex === "") {
        url = "https://staryh.me/yin/api/mv?" + "area=" + areaIndex;
        console.log(url);
      } else {
        url =
          "https://staryh.me/yin/api/mv?" +
          "area=" +
          areaIndex +
          "&version=" +
          versionIndex;
        console.log(url);
      }

      function formatDate(timestamp) {
        const date = new Date(timestamp * 1000);
        const year = date.getFullYear();
        const month = ("0" + (date.getMonth() + 1)).slice(-2);
        const day = ("0" + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
      }
      this.$http.get(url).then((res) => {
        this.mv = res.data.data;
        console.log(this.mv);
        // 将时间戳格式化为时间
        this.mv.forEach((item) => {
          item.time = formatDate(item.time);
        });
        console.log(this.mv);
      });
    },

    // 跳转到播放界面
    routerPlay(id) {
      //     console.log(id);
      //   this.$router.push({
      //     name: "mvPlay",
      //     params: {
      //       id: id,
      //     },
      //   });
      let url = `/#/mvPlay?mvId=${encodeURIComponent(id)}`;

      window.open(`${window.location.origin}${url}`, "_blank");
    },
  },
};
</script>
  
  <style lang="less" scoped>
.el-container {
  padding: 0;
}

.el-header,
.el-footer {
  position: relative;
  color: #3333;
  text-align: center;
  width: 100%;
  min-width: 1200px;
  padding: 0;
}
.el-footer {
  box-shadow: none !important;
}
.el-aside {
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  margin-top: 10px;
  width: 1265px;
  min-width: 1265px;
  margin: 0 auto;
  padding: 0%;
  color: #333;
  text-align: center;
  background-color: bga(250, 250, 250) !important;
  overflow-y: hidden;
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

.el-menu-demo {
  padding-left: 150px;
}

.search {
  padding: 8px 0;
}

.login {
  // position: relative;
  // margin-left: 460px;
  margin-top: 22px;
  right: -460px;
  cursor: default;
}

.user {
  position: absolute;
  cursor: default;
  left: 1200px;
  top: -3px;
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
}
.bg-purple {
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  position: relative;
  margin-top: 15px;
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.el-menu--horizontal > .el-menu-item {
  color: #333;
  font-size: 16px;
}

.el-row {
  margin-bottom: 20px;
  margin-left: 0% !important;
  margin-right: 0% !important;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
}
.bg-purple {
  height: 260px;
}
.bg-purple-light {
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
}
.el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
  font-weight: 400;
  color: #606266;
  cursor: default;
}
.bg-purple[data-v-47323bf2] {
  background: none;
  height: opx;
}
.el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
  font-weight: 400;
  color: #606266;
  cursor: default !important;
}
.el-avatar {
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
  color: #fff;
  width: 60px;
  height: 60px;
  line-height: 40px;
  background: none;
}

.hide ul li:hover {
  color: rgb(0, 179, 255);
}
el-menu-item:hover {
  color: rgb(0, 179, 255);
}

/* 设置鼠标经过时的背景颜色 */
.el-menu--horizontal > .el-menu-item:hover {
  color: rgb(0, 179, 255) !important;
}

/* 设置鼠标经过时的文字颜色 */
.el-menu--horizontal > .el-menu-item:hover > a,
.el-menu--horizontal > .el-menu-item.is-active > a {
  color: rgb(0, 179, 255) !important;
}
.rea_tag,
.version_tag {
  width: 100%;
  height: 41px;
}
.main .version_tag ul li,
.main .rea_tag ul li {
  list-style: none;
  padding-right: 62px;
  float: left;
  margin-top: 20px;
  font-size: 16px;
  color: #000;
}
.move:hover {
  color: rgb(0, 179, 255) !important;
  cursor: pointer;
}

.content {
  height: 100%;
  margin-top: 20px;
  font-size: 16px;
  text-align: left;
  .part {
    margin-left: 37px;
  }
  span {
    margin-right: 15px;
  }
}
.mv_content {
  margin-top: 20px;
  margin-left: 28px;
  height: 100%;
}
.demo-image__lazy {
  width: 285px;
  height: 165px;
}
.title {
  margin-top: 10px;
  font-size: 14px;
  color: #000;
}
.singer {
  margin-top: 10px;
  font-size: 14px;
  color: #908d8d;
}
.detail {
  margin-top: 10px;
  font-size: 14px;
  color: #989393;
}
.bofang {
  display: none;
  position: absolute;
  left: 118px;
  top: 56px;
  opacity: 0;
  transition: opacity 0.3s ease;
}
// 鼠标经过时显示 并且图片放大一点

// .grid-content:hover .bofang{
//     display: block;
// }
.demo-image__lazy {
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease; /* 添加过渡效果 */
}

.demo-image__lazy:hover {
  transform: scale(1.05); /* 放大图片 */
}
.mv-item:hover .bofang {
  display: block;
  opacity: 1;
}
</style>
  