<template>
  <div id="app">
    <el-container>
      <!-- 头部 -->
      <el-header>
        <el-menu class="el-menu-demo" mode="horizontal">
          <el-menu-item index="1">
            <span style="font-size: 40px; color: #909399">star</span>
            <i
              class="iconfont icon-yinletai"
              style="font-size: 52px; color: #909399"
            ></i
          ></el-menu-item>
          <el-menu-item index="1" @click="index">首页</el-menu-item>
          <el-menu-item index="1" @click="router1">歌手</el-menu-item>
          <el-menu-item index="1">Mv</el-menu-item>
          <el-menu-item index="1" @click="rank">排行榜</el-menu-item>
          <el-menu-item index="1" @click="mymusic">我的音乐</el-menu-item>
          <el-menu-item index="1">
            <!-- 搜索 -->
            <el-form ref="form" :model="form" label-width="0px">
              <el-form-item class="search">
                <el-input
                  v-model="form.content"
                  placeholder="歌手 歌单 歌曲"
                  size="medium"
                >
                  <template v-slot:suffix>
                    <i
                      class="el-icon-search"
                      @click="handleIconClick"
                      style="cursor: pointer"
                    ></i>
                  </template>
                </el-input>
              </el-form-item>
            </el-form>
          </el-menu-item>
          <!-- 登录 -->
          <el-menu-item index="1">
            <el-breadcrumb separator="/">
              <el-breadcrumb-item
                :to="{ path: '/login' }"
                class="login"
                style="cursor: default"
                ><span style="cursor: default" id="judgelogin"
                  >登录</span
                ></el-breadcrumb-item
              >
            </el-breadcrumb>
          </el-menu-item>
          <!-- 管理员显示 -->
          <!-- <el-menu-item>
            <div class="admin">
              <span
                style="font-size: 14px; color: red"
                id="judgeAdmin"
                @click="admin"
                >管理员</span
              >
            </div>
          </el-menu-item> -->
          <!-- 头像 -->
          <el-popover placement="top-start" width="200" trigger="hover">
            <div class="hide">
              <ul>
                <li @click="mymusic">我的资料</li>
                <li @click="exit">退出登录</li>
              </ul>
            </div>
            <div slot="reference" class="user" @click="mymusic">
              <el-avatar :src="url"></el-avatar>
            </div>
          </el-popover>
        </el-menu>
      </el-header>
      <!-- 中间部分 -->
      <div class="el-main">
        <div class="main">
          <!-- video element -->
          <vue-plyr :options="options">
            <video
              controls
              crossorigin
              playsinline
              data-poster="this.mv.imgSrc"
              :src="this.mv.mvUrl"
              autoplay
              @loadeddata="onVideoLoaded"
            >
              <source size="720" :src="this.mv.mvUrl" type="video/mp4" />
              <source size="1080" :src="this.mv.mvUrl" type="video/mp4" />
              <track
                default
                kind="captions"
                label="English captions"
                :src="this.mv.mvUrl"
                srclang="en"
              />
            </video>
          </vue-plyr>
          <i
            id="font"
            class="el-icon-loading"
            style="color: #fff; font-size: 34px"
            v-if="!isVideoLoaded"
          ></i>

          <div class="singer">
            <span>{{ this.mv.singer }}</span>
            <span> - </span>
            <span>{{ this.mv.title }}</span>
          </div>
        </div>
      </div>
      <div class="foot">
        <div class="intro">视频简介</div>
        <span class="singername">{{ this.mv.singer }}</span>
        <span>{{ this.time }}</span>
      </div>
    </el-container>
  </div>
</template>
    
    <script>
import router from "@/router";
import Cookies from "js-cookie";
export default {
  name: "index",

  data() {
    return {
      time: "",
      url: "",
      mv: [],
      // 搜索
      form: {
        search: "content",
      },
      isVideoLoaded: false,
      options: {
        autoplay: true,
        i18n: {
          restart: "重新播放",
          rewind: "快退 {seektime} 秒",
          play: "播放",
          pause: "暂停",
          fastForward: "快进 {seektime} 秒",
          seek: "跳转",
          played: "已播放",
          buffered: "已缓存",
          currentTime: "当前时间",
          duration: "总时长",
          volume: "音量",
          mute: "静音",
          unmute: "取消静音",
          enableCaptions: "打开字幕",
          disableCaptions: "关闭字幕",
          enterFullscreen: "全屏",
          exitFullscreen: "退出全屏",
          frameTitle: "播放器 {title}",
          captions: "字幕",
          settings: "设置",
          speed: "速度",
          normal: "正常",
          quality: "画质",
          loop: "循环",
        },
      },
    };
  },
  // 定义钩子函数
  mounted() {
    const button = document.querySelector(".plyr__control--overlaid");
    button.style.display = "none";
    // 判断是否为管理员
    // if (Cookies.get("username") != "admin") {
    //   let username = Cookies.get("username");
    //   let admin = document.getElementById("judgeAdmin");
    //   admin.style.display = "none";
    // }
    // 判断是否登录
    if (Cookies.get("username") != null) {
      let username = Cookies.get("username");
      let login = document.getElementById("judgelogin");
      let url = "https://staryh.me/yin/api/users/info?username=" + username;
      login.style.display = "none";
      this.$http.get(url).then((res) => {
        this.url = res.data.data.userImg;
      });
    }
    const urlParams = new URLSearchParams(window.location.hash.split("?")[1]);
    const id = urlParams.get("mvId");
    this.selectAll(id);
  },

  // 轮播图
  methods: {
    // 搜索表单验证
    handleIconClick() {
      this.$router.push({
        name: "search",
        params: {
          content: this.form.content,
        },
      });
    },
    // 跳转到歌手界面
    router1() {
      this.$router.push("/singerlist");
    },
    // 跳转到首页
    index() {
      this.$router.push("/index");
    },

    // 我的音乐
    mymusic() {
      this.$router.push("/user");
    },
    admin() {
      this.$router.push("/admin");
    },
    // 退出登录
    exit() {
      Cookies.remove("username");
      this.$router.push("/login");
    },
    // 跳转到排行榜
    rank() {
      this.$router.push("/rank");
    },
    onVideoLoaded() {
      this.isVideoLoaded = true;
      const button = document.querySelector(".plyr__control--overlaid");
      button.style.display = "block";
    },
    // 通过id查询对应的mv相关信息
    selectAll(id) {
      function formatDate(timestamp) {
        const date = new Date(timestamp * 1000);
        const year = date.getFullYear();
        const month = ("0" + (date.getMonth() + 1)).slice(-2);
        const day = ("0" + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
      }
      let url = "https://staryh.me/yin/api/mv/" + id;
      this.$http.get(url).then((res) => {
        this.mv = res.data.data;
        this.time = formatDate(this.mv.time);
      });
    },
  },
};
</script>
    
    <style lang="less" scoped>
.el-container {
  padding: 0;
}

.el-header,
.el-footer {
  position: relative;
  color: #3333;
  text-align: center;
  width: 100%;
  min-width: 1200px;
  padding: 0;
}
.el-footer {
  box-shadow: none !important;
}
.el-aside {
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  margin-top: 10px;
  width: 1265px;
  min-width: 1265px;
  margin: 0 auto;
  padding: 0%;
  color: #333;
  background-color: bga(250, 250, 250) !important;
  overflow-y: hidden;
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

.el-menu-demo {
  padding-left: 150px;
}

.search {
  padding: 8px 0;
}

.login {
  // position: relative;
  // margin-left: 460px;
  margin-top: 22px;
  right: -460px;
  cursor: default;
}

.user {
  position: absolute;
  cursor: default;
  left: 1200px;
  top: -3px;
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
}
.bg-purple {
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  position: relative;
  margin-top: 15px;
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.el-menu--horizontal > .el-menu-item {
  color: #333;
  font-size: 16px;
}

.el-row {
  margin-bottom: 20px;
  margin-left: 0% !important;
  margin-right: 0% !important;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
}
.bg-purple {
  height: 260px;
}
.bg-purple-light {
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
}
.el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
  font-weight: 400;
  color: #606266;
  cursor: default;
}
.bg-purple[data-v-47323bf2] {
  background: none;
  height: opx;
}
.el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
  font-weight: 400;
  color: #606266;
  cursor: default !important;
}
.el-avatar {
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
  color: #fff;
  width: 60px;
  height: 60px;
  line-height: 40px;
  background: none;
}

.hide ul li:hover {
  color: rgb(0, 179, 255);
}
el-menu-item:hover {
  color: rgb(0, 179, 255);
}

/* 设置鼠标经过时的背景颜色 */
.el-menu--horizontal > .el-menu-item:hover {
  color: rgb(0, 179, 255) !important;
}

/* 设置鼠标经过时的文字颜色 */
.el-menu--horizontal > .el-menu-item:hover > a,
.el-menu--horizontal > .el-menu-item.is-active > a {
  color: rgb(0, 179, 255) !important;
}
.rea_tag,
.version_tag {
  width: 100%;
  height: 41px;
}
.main .version_tag ul li,
.main .rea_tag ul li {
  list-style: none;
  padding-right: 62px;
  float: left;
  margin-top: 20px;
  font-size: 16px;
  color: #000;
}
.main {
  position: relative;
  width: 1300px;
  margin: auto;
}
.el-main {
  width: 100%;
  background-color: rgb(51, 51, 51);
}
.move:hover {
  color: rgb(0, 179, 255) !important;
  cursor: pointer;
}
.singer {
  height: 78px;
  width: 100%;
  color: #fff;
  line-height: 78px;
  font-size: 26px;
}
.foot {
  width: 100%;
  height: 100px;
  color: #000;
  font-size: 16px;
  line-height: 100px;
  .intro {
    margin-left: 194px;
    font-size: 26px;
    margin-bottom: -50px;
  }
  .singername {
    margin-left: 194px;
  }
  span {
    margin-left: 20px;
    font-size: 16px;
    color: #000000;
  }
}

.hide {
  width: 150px;
}
.hide ul li {
  list-style: none;
  margin-top: 10px;
  margin-left: 10px;
  font-size: 18px;
  color: #333;
  cursor: default;
}

.hide ul li:hover {
  color: rgb(0, 179, 255);
}
el-menu-item:hover {
  color: rgb(0, 179, 255);
}

/* 设置鼠标经过时的背景颜色 */
.el-menu--horizontal > .el-menu-item:hover {
  color: rgb(0, 179, 255) !important;
}

/* 设置鼠标经过时的文字颜色 */
.el-menu--horizontal > .el-menu-item:hover > a,
.el-menu--horizontal > .el-menu-item.is-active > a {
  color: rgb(0, 179, 255) !important;
}
#font {
  position: absolute;
  left: 50%;
  top: 280px;
}
</style>
    