<template>
  <div id="app">
    <el-container>
      <!-- 头部 -->
      <el-header>
        <el-menu class="el-menu-demo" mode="horizontal">
          <el-menu-item index="1">
            <span style="font-size: 40px; color: #909399">star</span>
            <i
              class="iconfont icon-yinletai"
              style="font-size: 52px; color: #909399"
            ></i
          ></el-menu-item>
          <el-menu-item index="1" @click="index">首页</el-menu-item>
          <el-menu-item index="1" @click="router1">歌手</el-menu-item>
          <el-menu-item index="1">Mv</el-menu-item>
          <el-menu-item index="1" >排行榜</el-menu-item>
          <el-menu-item index="1" @click="mymusic">我的音乐</el-menu-item>
          <el-menu-item index="1">
            <!-- 搜索 -->
            <el-form ref="form" :model="form" label-width="0px">
              <el-form-item class="search">
                <el-input
                  v-model="form.content"
                  placeholder="歌手 歌单 歌曲"
                  size="medium"
                >
                  <template v-slot:suffix>
                    <i
                      class="el-icon-search"
                      @click="handleIconClick"
                      style="cursor: pointer"
                    ></i>
                  </template>
                </el-input>
              </el-form-item>
            </el-form>
          </el-menu-item>
          <!-- 登录 -->
          <el-menu-item index="1">
            <el-breadcrumb separator="/">
              <el-breadcrumb-item
                :to="{ path: '/login' }"
                class="login"
                style="cursor: default"
                ><span style="cursor: default" id="judgelogin"
                  >登录</span
                ></el-breadcrumb-item
              >
            </el-breadcrumb>
          </el-menu-item>
          <!-- 管理员显示 -->
          <el-menu-item>
            <div class="admin">
              <span
                style="font-size: 14px; color: red"
                id="judgeAdmin"
                @click="admin"
                >管理员</span
              >
            </div>
          </el-menu-item>
          <!-- 头像 -->
          <el-popover placement="top-start" width="200" trigger="hover">
            <div class="hide">
              <ul>
                <li @click="mymusic">我的资料</li>
                <li @click="exit">退出登录</li>
              </ul>
            </div>
            <div slot="reference" class="user" @click="mymusic">
              <el-avatar :src="url"></el-avatar>
            </div>
          </el-popover>
        </el-menu>
      </el-header>
      <!-- 中间部分 -->
      <div class="el-main">
        <div class="main">
          <div class="left">
            <ul>
              <div>巅峰榜</div>
              <li @click="topic(1)" id="1">飙升榜</li>
              <li @click="topic(2)" id="2">热歌榜</li>
              <li @click="topic(3)" id="3">新歌榜</li>
              <li @click="topic(4)" id="4">流行指数榜</li>
              <div>地区榜</div>
              <li @click="topic(5)" id="5">内地榜</li>
              <li @click="topic(6)" id="6">欧美榜</li>
              <li @click="topic(7)" id="7">台湾榜</li>
              <li @click="topic(8)" id="8">港台榜</li>
              <li @click="topic(9)" id="9">日本榜</li>
              <div>特色榜</div>
              <li @click="topic(10)" id="10">影视金曲</li>
              <li @click="topic(11)" id="11">抖音榜</li>
              <li @click="topic(12)" id="12">网络歌曲榜</li>
              <li @click="topic(13)" id="13">动漫音乐榜</li>
              <div>全球榜</div>
              <li @click="topic(14)" id="14">美国公告牌榜</li>
              <li @click="topic(15)" id="15">韩国Melon榜</li>
              <li @click="topic(16)" id="16">其它榜</li>
            </ul>
          </div>
          <div class="right">
            <div class="title">巅峰榜</div>
            <div class="head_r">
              <div class="playall">
                <el-button type="primary" 
                @click="playAllSongs()"
                >
                  <i
                    class="iconfont icon-bofangqi-bofang"
                    style="
                      font-size: 22px;
                      color: #fff;
                      position: relative;
                      top: 3px;
                      right: 5px;
                    "
                  ></i
                  >播放全部</el-button
                >
              </div>
              <div class="download">
                <el-button type="primary"
                @click="downloadAllSongs()"
                >
                  <i
                    class="iconfont icon-xiazai"
                    style="
                      font-size: 22px;
                      color: #fff;
                      position: relative;
                      top: 3px;
                      right: 5px;
                    "
                  ></i
                  >下载</el-button
                >
              </div>
            </div>
            <div class="songlist_head">
              <div class="songlist_head1">歌曲</div>
              <div class="songlist_head2">歌手</div>
              <div class="songlist_head4">时长</div>
            </div>
            <div
              class="songlist_content"
              v-for="(item, index) in song"
              :key="item.id"
            >
              <div class="songlist_content1">
                {{ index + 1 }}
                <span
                  style="margin-left: 15px; color: #6a6868; font-weight: 400"
                  >一</span
                >
              </div>
              <div class="songlist_content2">
                <div class="img">
                  <el-image
                    style="width: 70px; height: 70px"
                    :src="item.coverPic"
                  ></el-image>
                </div>
                <div class="song_name">{{ item.songName }}</div>
                <div class="icon" @click="playSongs(item.id)">
                  <i
                    class="iconfont icon-bofanganniu"
                    style="font-size: 40px; color: #999999"
                  ></i>
                </div>
                <div class="download">
                  <i
                    class="iconfont icon-xiazai"
                    style="font-size: 40px; color: #999999"
                  ></i>
                </div>
              </div>
              <div class="songlist_content3">{{ item.singName }}</div>
              <div class="songlist_content4">{{ item.timeInterval }}</div>
            </div>
          </div>
        </div>
      </div>
    </el-container>
  </div>
</template>
      
      <script>
import router from "@/router";
import Cookies from "js-cookie";
export default {
  name: "index",

  data() {
    return {
      time: "",
      url: "",
      song: [],
      // 搜索
      form: {
        search: "content",
      },
      isVideoLoaded: false,
    };
  },
  // 定义钩子函数
  mounted() {
    // 判断是否为管理员
    if (Cookies.get("username") != "admin") {
      let username = Cookies.get("username");
      let admin = document.getElementById("judgeAdmin");
      admin.style.display = "none";
    }
    // 判断是否登录
    if (Cookies.get("username") != null) {
      let username = Cookies.get("username");
      let login = document.getElementById("judgelogin");
      let url = "https://staryh.me/yin/api/users/info?username=" + username;
      login.style.display = "none";
      this.$http.get(url).then((res) => {
        this.url = res.data.data.userImg;
      });
    }
    this.selectMusic(1);
  },

  // 轮播图
  methods: {
    // 搜索表单验证
    handleIconClick() {
      this.$router.push({
        name: "search",
        params: {
          content: this.form.content,
        },
      });
    },
    // 跳转到歌手界面
    router1() {
      this.$router.push("/singerlist");
    },
    // 跳转到首页
    index() {
      this.$router.push("/index");
    },

    // 我的音乐
    mymusic() {
      this.$router.push("/user");
    },
    admin() {
      this.$router.push("/admin");
    },
    // 退出登录
    exit() {
      Cookies.remove("username");
      this.$router.push("/login");
    },
    topic(id) {
      this.selectMusic(id);
      // 获取li里面的值
      let area = document.getElementById(id);
      // 将所有选项的颜色都设为原始颜色
      let lis = document.querySelectorAll(".left ul li");
      for (let i = 0; i < lis.length; i++) {
        lis[i].style.color = "#333";
      }
      area.style.color = "rgb(0, 179, 255)";
    },
    //   默认请求热歌榜
    selectMusic(id) {
      let url = "https://staryh.me/yin/api/rank/select/" + id;
      this.$http.get(url).then((res) => {
        // console.log(res.data);
        this.song = res.data.data;
        this.song.map((song) => {
          let minutes = Math.floor(parseInt(song.timeInterval) / 60);
          let seconds = parseInt(song.timeInterval) % 60;
          song.timeInterval = `${minutes.toString().padStart(2, "0")}:${seconds
            .toString()
            .padStart(2, "0")}`;
          return song;
        });
      });
    },
    // 播放音乐
    playSongs(id) {
      let url1 = "https://staryh.me/yin/api/rank/selectSongById/" + id;
      this.$http.get(url1).then((res) => {
        // console.log(res.data.data);
        // 进行转发
        let url = `/#/play?singerName=${encodeURIComponent(
          res.data.data.singName
        )}&songName=${encodeURIComponent(
          res.data.data.songName
        )}&albumTitle=${encodeURIComponent(
            res.data.data.albumTitle
        )}&imagePath=${encodeURIComponent(
            res.data.data.singPic
        )}&topId=${encodeURIComponent(
            res.data.data.topId
        )}&timeInterval=${encodeURIComponent(
            res.data.data.timeInterval
        )}&currentId=${encodeURIComponent(
            res.data.data.id
        )}&identify=${encodeURIComponent(3)}`;
        window.open(url, "_blank");
      });
    },
    // 播放全部
    playAllSongs(){
        this.$message({
            message: '功能暂未开放',
            type: 'warning'
          });
    },
    // 下载全部
    downloadAllSongs(){
        this.$message({
            message: '功能暂未开放',
            type: 'warning'
          });
    },
  },
};
</script>
      
      <style lang="less" scoped>
.el-container {
  padding: 0;
}

.el-header,
.el-footer {
  position: relative;
  color: #3333;
  text-align: center;
  width: 100%;
  min-width: 1200px;
  padding: 0;
}
.el-footer {
  box-shadow: none !important;
}
.el-aside {
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  margin-top: 10px;
  width: 1360px;
  min-width: 1360px;
  margin: 0 auto;
  padding: 0%;
  color: #333;
  background-color: bga(250, 250, 250) !important;
  overflow-y: hidden;
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

.el-menu-demo {
  padding-left: 150px;
}

.search {
  padding: 8px 0;
}

.login {
  // position: relative;
  // margin-left: 460px;
  margin-top: 22px;
  right: -460px;
  cursor: default;
}

.user {
  position: absolute;
  cursor: default;
  left: 1100px;
  top: -3px;
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
}
.bg-purple {
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  position: relative;
  margin-top: 15px;
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.el-menu--horizontal > .el-menu-item {
  color: #333;
  font-size: 16px;
}

.el-row {
  margin-bottom: 20px;
  margin-left: 0% !important;
  margin-right: 0% !important;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
}
.bg-purple {
  height: 260px;
}
.bg-purple-light {
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
}
.el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
  font-weight: 400;
  color: #606266;
  cursor: default;
}
.bg-purple[data-v-47323bf2] {
  background: none;
  height: opx;
}
.el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
  font-weight: 400;
  color: #606266;
  cursor: default !important;
}
.el-avatar {
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
  color: #fff;
  width: 60px;
  height: 60px;
  line-height: 40px;
  background: none;
}

.hide ul li:hover {
  color: rgb(0, 179, 255);
}
el-menu-item:hover {
  color: rgb(0, 179, 255);
}

/* 设置鼠标经过时的背景颜色 */
.el-menu--horizontal > .el-menu-item:hover {
  color: rgb(0, 179, 255) !important;
}

/* 设置鼠标经过时的文字颜色 */
.el-menu--horizontal > .el-menu-item:hover > a,
.el-menu--horizontal > .el-menu-item.is-active > a {
  color: rgb(0, 179, 255) !important;
}

.move:hover {
  color: rgb(0, 179, 255) !important;
  cursor: pointer;
}

.hide ul li:hover {
  color: rgb(0, 179, 255);
}
el-menu-item:hover {
  color: rgb(0, 179, 255);
}

/* 设置鼠标经过时的背景颜色 */
.el-menu--horizontal > .el-menu-item:hover {
  color: rgb(0, 179, 255) !important;
}

/* 设置鼠标经过时的文字颜色 */
.el-menu--horizontal > .el-menu-item:hover > a,
.el-menu--horizontal > .el-menu-item.is-active > a {
  color: rgb(0, 179, 255) !important;
}

.main {
  width: 1360px;

  margin: auto;
  margin-top: 60px;

  .left {
    width: 270px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 0px;
    margin-right: 50px;
    margin-left: 10px;
    float: left;
    ul {
      padding-left: 0%;

      div {
        padding-left: 40px;
        height: 52px;
        line-height: 52px;
        font-size: 22px;
        font-weight: bold;
        border-bottom: 1px solid #efebeb;
      }
      li {
        padding-left: 40px;
        padding-right: 40px;
        list-style: none;
        height: 52px;
        line-height: 52px;
        font-size: 18px;
        cursor: pointer;
      }
      li:hover {
        color: rgb(0, 179, 255);
      }
    }
  }
  .right {
    width: 970px;
    float: left;

    .title {
      font-size: 22px;
      font-weight: bold;
    }
    .head_r {
      margin-top: 20px;
      font-size: 18px;
      overflow: hidden;
      .play_all {
        float: left;
        background-color: rgb(0, 179, 255);
      }
      .download {
        float: left;
        margin-left: 20px;
      }
    }
    .songlist_head {
      margin-top: 20px;
      height: 40px;
      line-height: 40px;
      color: #999999;
      font-size: 16px;
      padding-left: 120px;
      padding-right: 20px;
      border-radius: 4px;
      .songlist_head1 {
        float: left;
        width: 500px;
      }
      .songlist_head2 {
        float: left;
        width: 200px;
      }
      .songlist_head4 {
        float: left;
        width: 100px;
      }
    }
    .songlist_content {
      margin-top: 20px;
      height: 80px;
      line-height: 40px;
      color: #999999;
      font-size: 16px;
      border-radius: 4px;
      .songlist_content1 {
        float: left;
        font-size: 24px;
        line-height: 80px;
        color: #ff4222;
        width: 120px;
      }
      .songlist_content2 {
        float: left;
        width: 500px;
        position: relative;
        // 多余文字隐藏

        .icon {
          position: relative;
          display: none;
          width: 40px;
          height: 40px;
          left: 384px;
          top: -50px;
        }
        .download {
          position: relative;
          display: none;
          width: 40px;
          height: 40px;
          left: 444px;
          top: -50px;
        }
        .download :hover {
          color: rgb(0, 179, 255) !important;
        }
        .icon :hover {
          color: rgb(0, 179, 255) !important;
        }
        .img {
          float: left;
          width: 70px;
          height: 70px;
        }
        .song_name {
          width: 200px;
          float: left;
          height: 70px;
          line-height: 70px;
          margin-left: 15px;
          color: #000000;
          font-size: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .songlist_content2:hover .icon,
      .songlist_content2:hover .download {
        display: block;
      }
      .songlist_content3 {
        height: 70px;
        line-height: 70px;
        float: left;
        width: 200px;
        color: #000000;
        font-size: 16px;
      }
      .songlist_content4 {
        float: left;
        width: 140px;
        height: 70px;
        line-height: 70px;
        color: #999999;
        font-size: 16px;
      }
    }
  }
}
/deep/ .el-button--primary {
  float: left;
}
/deep/ .el-button {
  font-size: 16px;
  background-color: rgb(0, 179, 255);
}
</style>
      