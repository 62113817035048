import { render, staticRenderFns } from "./singerlist.vue?vue&type=template&id=fcffd3aa&scoped=true&"
import script from "./singerlist.vue?vue&type=script&lang=js&"
export * from "./singerlist.vue?vue&type=script&lang=js&"
import style0 from "./singerlist.vue?vue&type=style&index=0&id=fcffd3aa&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fcffd3aa",
  null
  
)

export default component.exports